import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import PostOfficeSelection from '@pages/main/visits/components/visit/compoments/common/post-office-select';
import { PostalService } from '@services/postal';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

import { replaceNumber } from '../../../../../utils/replaceNumber';
import styles from './index.module.scss';

const ModalOpsChange: FC = () => {
	const store = useStore();
	const [phone, setPhone] = useState('');
	const [modalError, setModalError] = useState('');
	const [modalStage, setModalStage] = useState(1);

	const close = (): void => {
		setPhone('');
		setModalStage(1);
		setModalError('');
		store.extendedSearch.setIdOpsChange(null);
	};

	const confirm = (): void => {
		const id = store.extendedSearch.idOpsChange;
		if (!id) return;

		store.setLoading(true);

		if (modalStage === 1) {
			phone &&
				PostalService.postalCheck(id, { phone_number: String(phone) })
					.then(() => setModalStage(2))
					.catch((err) => setModalError(err?.error || err?.message || 'Неверный телефон отправителя'))
					.finally(() => store.setLoading(false));
			return;
		}

		PostalService.putPostal(id, { store_id_start: store.postOffice as number })
			.then(() => {
				store.notice.setNotice({ message: 'Успешно', type: 'success' });
				close();
			})
			.catch((err) => store.notice.setNotice({ message: err?.error || err?.message || '' }))
			.finally(() => store.setLoading(false));
	};

	const handlePhoneChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setModalError('');
		setPhone(replaceNumber(evt.target.value));
	};

	const handleOpsChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
		setModalError('');
		store.extendedSearch.setNumberOpsChange(evt.target.value);
	};

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.modal__body}
			title={null}
			okText="Продолжить"
			cancelText="Отмена"
			open={!!store.extendedSearch.idOpsChange}
			onCancel={close}
			onOk={confirm}
			okButtonProps={{
				disabled:
					(modalStage === 1 ? !phone : !store.extendedSearch.numberOpsChange || !store.postOffice) || !!modalError,
			}}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			{modalStage === 1 ? (
				<>
					<h3 className={styles.modal__title}>Проверки заявки для изменения</h3>
					<p>Введите последние 4 цифры номера телефона</p>
					<Input className={styles.modal__phone} value={phone} onChange={handlePhoneChange} maxLength={4} />
				</>
			) : (
				<>
					<h3 className={styles.modal__title}>Изменение ОПС отправки в ПчО</h3>
					<Input
						className={styles.modal__input}
						value={store.extendedSearch.numberOpsChange}
						onChange={handleOpsChange}
						required
						placeholder="Номер ПчО"
						disabled
					/>
					<PostOfficeSelection placeholder="Новое ОПС отправки" value={store.postOffice} disabled required />
				</>
			)}
			{modalError && <span className={styles.modal__error}>{modalError}</span>}
		</Modal>
	);
};

export default observer(ModalOpsChange);
