/* eslint-disable @typescript-eslint/no-empty-interface */
import { DriverType } from '@pages/main/shipment/components/route-sheet/components/info-modal/components/driver-modal';
import { ShipmentService } from '@services/shipment';
import { flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Sheet = model('sheet', {
	id: number,
	status: number,
	status_name: string,
	has_emall: union(literal(0), literal(1)),
	has_postal: union(literal(0), literal(1)),
	routing_spaces_count: number,
	date: maybeNull(string),
	driver_fio: maybeNull(string),
});

const RouteSheet = model('Route-Sheet', {
	list: maybeNull(array(Sheet)),
	showOld: optional(union(literal(0), literal(1)), 0),
	driverModal: optional(union(literal('add'), literal('del'), literal('confirm'), literal('')), ''),
	driverError: '',
	driverLabel: false,
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadList: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield ShipmentService.getLists(self.showOld);
				self.list = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setShowOld(value: boolean) {
			self.list = null;
			self.showOld = value ? 1 : 0;
			root.shipment.loadHeaderInfo();
		},
		setDriverModal(value: 'add' | 'del' | 'confirm' | '') {
			self.driverModal = value;
		},
		updateDriver: flow(function* (modalId: number | null, driver: DriverType | null) {
			if (!modalId) return;

			root.setLoading(true);

			try {
				yield ShipmentService.postDriver(
					modalId,
					driver ? { driver_market_id: driver.id, is_mercenary: driver.isMercenary } : { driver_market_id: null }
				);
				root.shipment.loadRoutingInfo(modalId);
				root.shipment.routeSheet.loadList();
				self.driverModal = '';
				self.driverError = '';
			} catch (err: any) {
				self.driverError = err?.error || err?.message || '';
			} finally {
				root.setLoading(false);
			}
		}),
		setDriverError(value: string) {
			self.driverError = value;
		},
		setDriverLabel(value: boolean) {
			self.driverLabel = value;
		},
		handleScanner(evt: KeyboardEvent) {
			const { modalInfo } = root.shipment;

			root.scanner.handleScanner(evt, (value) => {
				if (modalInfo && !modalInfo.isView) {
					let data: string | number | { type: string; owner: string; data: { driver_id: number } };

					try {
						data = JSON.parse(value);
					} catch {
						data = value;
					}

					if (typeof data === 'object') {
						const driverId = data.data.driver_id;
						if (self.driverModal === 'confirm') {
							modalInfo.id && root.shipment.confirmModalInfo(modalInfo.id, driverId);
							return;
						}
						this.updateDriver(modalInfo.id, {
							id: driverId,
							isMercenary: data.owner === 'mercenary_courier' ? 1 : 0,
						});
					} else {
						console.log(value, data);

						modalInfo.findSpace(String(value), { is_scanned: 1 });
					}
				}
			});
		},
	};
});

export interface Sheet extends SnapshotIn<typeof Sheet> {}
export default RouteSheet;
