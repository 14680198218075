import { api } from '@http/index';
import { IPostalSpace, ISpaces } from '@store/shipment/post/consolidation';
import { IPostal } from '@store/shipment/post/postal';
import { AxiosResponse } from 'axios';

const getPostalItems = (): Promise<AxiosResponse<IPostal[]>> => api.get(`/routing/shipment/postal/items`);

const getPostalSpaces = (): Promise<AxiosResponse<ISpaces>> => api.get(`/routing/shipment/postal/spaces`);

const postPostalSpaces = (): Promise<AxiosResponse<ISpaces>> => api.post(`/routing/shipment/postal/spaces`);

const getPostalSpaceItems = (id: number, isStatic: boolean): Promise<AxiosResponse<IPostalSpace>> =>
	api.get(`/routing/shipment/postal/${isStatic ? 'static-places' : 'spaces'}/${id}/items`);

const getPostalNonShippable = (id: number): Promise<AxiosResponse<IPostal[]>> =>
	api.get(`/routing/shipment/postal/spaces/${id}/non-shippable`);

const postPostalTrouble = (id: number): Promise<AxiosResponse<void>> =>
	api.post(`/routing/shipment/postal/items/${id}/trouble`);

const putPostalUnscan = (id: number): Promise<AxiosResponse<void>> =>
	api.put(`/routing/shipment/postal/items/${id}/unscan`);

const postPostalScan = (data: {
	number: string;
	routing_space_id?: number;
	static_place_id?: number;
}): Promise<
	AxiosResponse<{
		routing_item: IPostal;
		scanning_status: 0 | 1 | 2 | 3;
		message: string;
	}>
> => api.post(`/routing/shipment/postal/item/scan`, data);

const postStaticPlacesShip = (id: number): Promise<AxiosResponse<IPostal[]>> =>
	api.post(`/routing/shipment/postal/static-places/${id}/ship`);

export const ShipmentPostalService = {
	getPostalItems,
	getPostalSpaces,
	postPostalSpaces,
	getPostalSpaceItems,
	getPostalNonShippable,
	postPostalTrouble,
	putPostalUnscan,
	postPostalScan,
	postStaticPlacesShip,
};
