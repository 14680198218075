import { Order, Postal } from '@models/index';
import { checkPostalIcon } from 'utils/checkPostalIcon';

import { PostalTableDataType } from '../compoments/postal-table';
import { formatPhoneNumber } from './../../../../../../utils/formatPhone';

export const getTableDataFromPostalsAndOrders = (data: (Postal | Order)[]): PostalTableDataType[] => {
	return data.map<PostalTableDataType>((item) => {
		if ('type' in item) return getFromPostal(item);
		else return getFromOrder(item);
	});
};

const getFromPostal = (postal: Postal): PostalTableDataType => ({
	key: postal.id,
	header: {
		status: postal.market_status_name,
		image:
			postal.type === 0 || postal.type === 1
				? postal.type === 1
					? checkPostalIcon(postal.market_status, postal.agreement_type_id)
					: 'Mail'
				: 'Payments',
		number: postal.number,
		id: postal.id,
		dot: postal.type === 0,
		externalId: postal.external_id,
		statusColor: postal.market_status_name === 'Ошибка' ? 'red' : 'blue',
		isHighlight: postal.isHighlight,
	},
	names: {
		sender: postal.sender.fio,
		receiver: `${postal.receiver.surname} ${postal.receiver.name} ${postal.receiver.patronymic_name}`,
	},
	phones: {
		sender: formatPhoneNumber(postal.sender.phone_number),
		receiver: formatPhoneNumber(postal.receiver.phone_number),
	},
	isEditPostal: postal.type === 0 && [10, 15].includes(Number(postal?.market_status)),
	info: getPostalInfo(postal),
	tags: getPostalTags(postal),
	buttonType:
		(postal.type === 0 && postal.market_status === 0 && 'process') ||
		(postal.type === 1 &&
			!!postal.additional_services.is_completeness &&
			postal.isRequireCompletenessConfirm &&
			postal.market_status !== 96 &&
			'check') ||
		(postal.type === 1 && 'collect-parcel') ||
		null,
	type: (postal.type === 1 && 'postal') || (postal.type === 2 && 'payment') || 'requistion',
	postalObject: postal,
});

const getFromOrder = ({ payment_type, ...order }: Order): PostalTableDataType => ({
	key: order.id,
	header: {
		image: 'ShoppingBag',
		number: order.market_id,
		status: order.status_name,
		statusColor: order.status_name === 'Ошибка' ? 'red' : order.status_id === 90 ? 'green' : 'blue',
		id: order.id,
		isHighlight: order.isHighlight || false,
	},
	names: { receiver: order.receiver_fio },
	phones: { receiver: formatPhoneNumber(order.receiver_phone) },
	info: [`Сумма: ${String(order.sum ?? 0)} руб.`, payment_type === 2 ? 'Оплата онлайн' : ''],
	tags: order.bags?.map(({ name }) => name),
	type: 'order',
	orderObject: order,
	buttonType: order.is_collected === 0 ? 'not-collected' : null,
});

const getPostalInfo = (postal: Postal): string[] => {
	const { height, length, width, weight } = postal;
	if ((postal.type === 0 || postal.type === 1) && weight.value !== 0) {
		return [
			Number(width) && Number(height) && Number(length)
				? `${Math.round(Number(width))}*${Math.round(Number(height))}*${Math.round(Number(length))} см`
				: '',
			`${Math.round(Number(weight.value) * 100) / 100} кг`,
		];
	} else if (postal.type === 2) {
		return [
			`НП: ${postal.additional_services.payment_amount} руб.`,
			// postal.additional_services.payment_affiliation === 0 ? 'Оплачивает покупатель' : 'Оплачивает отправитель',
		];
	}
	return [];
};

const getPostalTags = (postal: Postal): string[] => {
	const { is_fragile, is_inventory, is_completeness, is_cash_on, is_oversize, is_declared } =
		postal.additional_services;
	const tags: string[] = [];
	if (is_fragile) tags.push('Хрупкость');
	if (is_inventory) tags.push('Опись');
	if (is_completeness) tags.push('Проверка');
	if (is_cash_on) tags.push('НП');
	if (is_oversize) tags.push('Громоздкость');
	if (is_declared) tags.push('ОЦ');
	return tags;
};
