import Button from '@components/buttons/button';
import colors from '@config/colors';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { PostalService } from '@services/postal';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useState } from 'react';

// import ConfirmModal, { ItemsType } from '../confirm-modal';
import InputNumber from './components/inputNumber';
import styles from './index.module.scss';

const Footer: FC = () => {
	const store = useStore();
	const visit = useCurrentVisit();

	const buttonClickHandler = (): void => {
		if (visit?.hasOrders) {
			visit.setShowModalUnionCheck();
			// } else if (visit?.inventoryPostals.length) {
			// 	setInventoryItems(visit.inventoryPostals.map(({ id, number }) => ({ id, name: 'Опись', number, active: false })));
		} else {
			visit?.close();
		}
	};
	// const confirmInventories: Parameters<typeof ConfirmModal>[0]['onConfirm'] = (items) => {
	// 	items.forEach(({ id, active }) => visit?.confirmInventory(id, active));
	// 	setInventoryItems([]);
	// 	// visitClose();
	// };

	return (
		<>
			<footer className={cn(styles.footer, { [styles.footer__error]: store.isPaymentError })}>
				{visit?.loadingPayment ? (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
						}}
						dangerouslySetInnerHTML={{
							__html: `<dotlottie-player
					src="/loading.json"
					background="transparent"
					speed="1"
					style="width: 120px; height: 120px"
					direction="1"
					mode="normal"
					loop
					autoplay
				></dotlottie-player>`,
						}}
					/>
				) : !store.isPaymentError ? (
					<>
						<ul className={styles.totals}>
							{/* {visit && (visit.certificateSum > 0 || (!visit.hasOrders && !!visit.paymentSum && !!visit.issueSum)) && ( */}
							{visit && (visit.certificateSum > 0 || !visit.hasOrders) && (
								<PaymentInfo type="pay" sum={visit?.paymentSum} />
							)}
							{!!visit?.certificateSum && <PaymentInfo type="sert" sum={visit.certificateSum} />}
							{/* {!visit?.hasOrders && !!visit?.paymentSum && !!visit.issueSum && ( */}
							{!visit?.hasOrders && <PaymentInfo type="give" sum={visit?.issueSum} />}
							{(visit?.finalSum != 0 || visit.paidOnline) && (
								<Summary sum={visit?.finalSum} online={visit?.hasOrders && visit.finalSum === 0} />
							)}
							<PaymentInput />
						</ul>
						<div className={styles.confirm}>
							<Button
								type="primary"
								size="large"
								disabled={!visit?.confirmEnabled}
								onClick={buttonClickHandler}
								danger={visit?.hasOrders}
							>
								{visit?.confirmText}
							</Button>
							{(visit?.hasRequistionsProcess || visit?.hasNotCollected) && (
								<p className={styles.confirm__description}>
									{visit?.hasRequistionsProcess ? 'Обработайте заявки' : 'Соберите заказы'} или удалите их из визита
								</p>
							)}
						</div>
						{/* {!!inventoryItems.length && (
							<ConfirmModal
								items={inventoryItems}
								open={true}
								onConfirm={confirmInventories}
								onCancel={() => setInventoryItems([])}
							/>
						)} */}
					</>
				) : (
					<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
						<p className={styles.error}>{store.notice.message}</p>
					</OverlayScrollbarsComponent>
				)}
			</footer>
		</>
	);
};

const PaymentInfo: FC<{ type: 'pay' | 'give' | 'sert'; sum?: number }> = ({ type, sum = 0 }) => {
	const text = (): string => {
		if (type === 'pay') return 'Итого к оплате, руб';
		if (type === 'give') return 'Итого к выдаче, руб';
		if (type === 'sert') return 'Оплачено сертификатом, руб';
		return '';
	};

	return (
		<li className={styles.totals__item}>
			<div className={styles.item__wrap}>
				<span className={styles.title}>{text()}</span>
				<b className={styles.value}>{sum}</b>
			</div>
		</li>
	);
};

const Summary: FC<{ sum?: number; online?: boolean }> = ({ sum = 0, online }) => {
	return (
		<li className={styles.totals__item}>
			<div className={styles.item__wrap}>
				<span className={styles.title}>{online ? 'Итого к оплате, руб' : 'Общий итог, руб'}</span>
				<b className={cn(styles.value, styles.value__inner)}>
					<Icon
						imageName={online ? 'CheckCircle' : 'ArrowCircleDown'}
						fontSize={32}
						color={online ? colors.green : sum < 0 ? colors.grey1 : colors.red}
						style={{ transform: `rotate(${sum < 0 ? '180' : 0}deg)` }}
					/>{' '}
					{online ? 'Оплачено онлайн' : `${sum < 0 ? 'Выдать' : 'Принять'} ${Math.abs(sum).toFixed(2)}`}
				</b>
			</div>
		</li>
	);
};

const autoFixSum = ({
	sum,
	change,
	paid,
	innerPaid,
}: {
	sum: number;
	change: number;
	paid: number | null;
	innerPaid: number | null;
}): number | null => {
	if (change < 0) return +(sum - (innerPaid || 0)).toFixed(2);
	if (change > 0) {
		const newSum = +((paid || 0) - change).toFixed(2);
		return newSum > 0 ? newSum : null;
	}
	return paid || null;
};

const PaymentInput: FC = observer(() => {
	const [isJuristic, setIsJuristic] = useState<boolean>(false);
	const visit = useCurrentVisit();
	const store = useStore();

	useEffect(() => {
		const id = setTimeout(() => {
			if (visit && visit.postals[0]) {
				visit.postals &&
					PostalService.getPostalInfo(visit.postals[0].id).then((el) => {
						setIsJuristic(!!el.data.is_juristic && el.data.type === 0);
					});
			}
		}, 100);

		return () => {
			clearTimeout(id);
		};
	}, []);

	if (visit && (isJuristic || visit?.finalSum === 0 || visit.hasOnlyPayments)) return null;

	return (
		<>
			<li className={cn(styles.totals__item, styles.payment)}>
				<div>
					<div className={styles.payment__wrap}>
						<label className={cn(styles.title, styles.payment__label)} htmlFor="payment-cash">
							Наличными, руб
						</label>
						<InputNumber
							className={styles.payment__input}
							id="payment-cash"
							value={visit?.paidInCash}
							onChange={(value) => visit?.payCash(Number(value) || null)}
							onDoubleClick={() => {
								if (visit) {
									const afs = autoFixSum({
										change: visit.change,
										sum: visit.finalSum,
										paid: visit.paidInCash,
										innerPaid: visit.paidInCard,
									});
									visit.payCash(afs);
									if (!afs && visit.paidInCard == visit.finalSum) {
										visit.payCash(visit.finalSum);
										visit.payCard(null);
									}
								}
							}}
						/>
					</div>
					<div className={styles.payment__wrap}>
						<label className={cn(styles.title, styles.payment__label)} htmlFor="payment-card">
							По карте, руб
						</label>
						<InputNumber
							className={styles.payment__input}
							id="payment-card"
							value={visit?.paidInCard}
							onChange={(value) => {
								visit?.payCard(Number(value) || null);
								if (visit?.isSumByCardExceeded) {
									store.notice.setNotice({ message: 'Сумма по карте превышает сумму к оплате', hasButton: false });
								}
							}}
							onDoubleClick={() => {
								if (visit) {
									const afs = autoFixSum({
										change: visit.change,
										sum: visit.finalSum,
										paid: visit.paidInCard,
										innerPaid: visit.paidInCash,
									});
									visit.payCard(afs);
									if (!afs && visit.paidInCash == visit.finalSum) {
										visit.payCard(visit.finalSum);
										visit.payCash(null);
									}
								}
							}}
						/>
					</div>
					{visit && visit.change > 0 && (
						<div className={styles.payment__wrap}>
							<span className={styles.title}>Сдача, руб</span>
							<b className={styles.value}>{visit.change.toFixed(2)}</b>
						</div>
					)}
				</div>
			</li>
		</>
	);
});

export default observer(Footer);
