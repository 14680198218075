/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, number, string, maybeNull, array, union, literal } = types;

const Order = model('Order', {
	bags: array(
		model('Bags', {
			id: number,
			market_id: number,
			name: string,
		})
	),
	certificate_sum: maybeNull(string),
	payment_type: maybeNull(number),
	id: number,
	market_id: number,
	status_id: number,
	status_name: string,
	receiver_fio: string,
	receiver_phone: string,
	sum: maybeNull(string),
	payment_affiliation: maybeNull(number),
	store_name: string,
	need_document: maybeNull(union(literal(0), literal(1))),
	is_collected: maybeNull(union(literal(0), literal(1))),
	issued_at: maybeNull(string),
	arrival_at: maybeNull(string),
	items: array(
		model('Item', {
			final_price: number,
			id: number,
			is_warranty_card: union(literal(0), literal(1)),
			name: string,
			price: number,
			quantity: number,
			received_quantity: maybeNull(number),
		})
	),
	is_adult: union(literal(0), literal(1)),
	store_place_item_full_names: maybeNull(array(string)),
	isHighlight: false,
}).actions((self) => ({
	setIsHighlight(value: boolean) {
		self.isHighlight = value;
	},
}));

export default Order;

export interface Order extends SnapshotIn<typeof Order> {}
