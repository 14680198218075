import { api } from '@http/index';
import { AxiosResponse } from 'axios';

const sendSmsCode = (id: number): Promise<AxiosResponse<{ message: string; attempts_remaining: number }>> =>
	api.post(`notifications/send-verification-code`, { account_id: id });

const sendSmsClientCode = (id: number): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`notifications/send-identification-code`, { account_id: id });

const checkSms = (id: number, smsCode: string): Promise<AxiosResponse<{ message: string }>> =>
	api.post(`notifications/check-sms-new`, { account_id: id, sms_code: smsCode });

export const NotificationsService = { sendSmsCode, checkSms, sendSmsClientCode };
