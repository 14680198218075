import { RootStore } from '@store/index';
import { getRoot, types } from 'mobx-state-tree';

import ConsolidationModel from './consolidation';
import ParcelsModel from './parcels';

const { model, union, literal, optional } = types;

const Post = model('post', {
	tab: optional(union(literal('consolidation'), literal('parcels')), 'consolidation'),
	parcels: ParcelsModel,
	consolidation: ConsolidationModel,
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);
	return {
		setTab(tab: 'consolidation' | 'parcels') {
			self.tab = tab;
		},
		handleScanner(evt: KeyboardEvent) {
			root.scanner.handleScanner(evt, (value) => {
				self.consolidation.modalGoodsTable
					?.scanBarcode(value)
					.catch((err: any) => console.log('post/scanner scanBarcode err:', err));
			});
		},
	};
});

export default Post;
