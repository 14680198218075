import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import Consolidation from './components/consolidation';
import Goods from './components/parcels';

let timerId: any;

const Post: FC = () => {
	const {
		shipment: {
			post: { tab, parcels, consolidation, handleScanner },
		},
	} = useStore();

	useScanner(handleScanner);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(tab === 'parcels' ? parcels.loadItems : consolidation.loadSpaces, 100);
		return () => clearTimeout(timerId);
	}, [tab]);

	return tab === 'parcels' ? <Goods /> : <Consolidation />;
};

export default observer(Post);
