import Input from '@components/input';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { ShipmentService } from '@services/shipment';
import { leaveEvent, listenChannel } from '@services/socket';
import { InputRef } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

const ConfirmModal: FC = () => {
	const inputRef = useRef<InputRef>(null);
	const [sign, setSign] = useState('');
	const {
		operator,
		notice,
		shipment: {
			post: { consolidation },
		},
	} = useStore();

	const closeConfirm = (): void => {
		leave();
		consolidation.modalGoodsTable?.setShowConfirm(false);
		setSign('');
	};

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && consolidation.modalGoodsTable?.showConfirm) {
			listenChannel(({ number }) => setSign(number), '.routing.number', operator.id);
		}

		return leave;
	}, [consolidation.modalGoodsTable?.showConfirm]);

	return (
		<Modal
			classNameModal={styles.confirm}
			className={styles.confirm__body}
			title={null}
			okText="Продолжить"
			cancelText="Отмена"
			open={consolidation.modalGoodsTable?.showConfirm}
			onCancel={closeConfirm}
			onOk={() => {
				const id = consolidation.modalGoodsTable?.space?.id;
				if (!id) return;

				ShipmentService.putSpaces(id, { number: sign })
					.then(() => {
						consolidation.loadSpaces();
						consolidation.loadSpace(null);
						closeConfirm();
						// print.printTask({ type: 'routing-surplus', id });
					})
					.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }));
			}}
			afterOpenChange={(open) => open && inputRef.current?.focus()}
			okButtonProps={{ disabled: !sign }}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.confirm__title}>Отсканируйте пломбу, которой закроете</h3>
			<Input value={sign} onChange={(evt) => setSign(evt.target.value)} ref={inputRef} />
		</Modal>
	);
};

export default observer(ConfirmModal);
