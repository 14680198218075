import { useEffect } from 'react';

export const useScanner = (handler: (evt: KeyboardEvent) => void): void => {
	useEffect(() => {
		window.addEventListener('keydown', handler);
		return () => window.removeEventListener('keydown', handler);
	}, []);

	return;
};
