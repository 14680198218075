import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
// import { AcceptanceService } from '@services/acceptance';
// import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC } from 'react';

import Header from './components/header';
import ItemsTable from './components/table';
import styles from './index.module.scss';

const ItemsProductModal: FC = () => {
	// const [showConfirm, setShowConfirm] = useState(false);
	const {
		notice,
		operator,
		acceptance: { emall },
		setLoading,
	} = useStore();

	const close = (): void => {
		// leave();
		emall.closeModalItems();
	};

	// const closeConfirm = (): void => {
	// 	setShowConfirm(false);
	// };

	// const closeItems = (): void => {
	// 	AcceptanceService.postSpacesClose()
	// 		.then(() => {
	// 			closeConfirm();
	// 			close();
	// 		})
	// 		.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
	// 		.finally(() => setLoading(false));
	// };

	// const submit = (): void => {
	// 	// if (emall.modalItems?.hasUnscanned) {
	// 	// 	setShowConfirm(true);
	// 	// } else {
	// 	closeItems();
	// 	// }
	// };

	// const leave = (): void => {
	// 	window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	// };

	// useEffect(() => {
	// 	if (window.Echo && operator?.id && emall.modalItems && !showConfirm) {
	// 		listenChannel(
	// 			({ number }) => {
	// 				emall.modalItems &&
	// 					emall.modalItems?.scanBarcode(number).catch((err) => console.log('emall scanBarcode err:', err));
	// 			},
	// 			'.routing.number',
	// 			operator.id
	// 		);
	// 	}

	// 	return leave;
	// }, [!!emall.modalItems, showConfirm]);

	return (
		<>
			<Modal
				title="Приемка товаров Emall"
				okText="Завершить приемку"
				cancelText="Закрыть"
				open={!!emall.modalItemsProduct}
				width={720}
				onCancel={close}
				onOk={close}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
				okButtonProps={{ disabled: !emall.modalItems?.hasItems }}
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<ItemsTable />
				</OverlayScrollbarsComponent>
			</Modal>
			{/* <Modal
				classNameModal={styles.confirm}
				className={styles.confirm__body}
				title={null}
				okText="Досканировать"
				cancelText="Принять с расхождением"
				open={showConfirm}
				onCancel={closeItems}
				onOk={closeConfirm}
				hasLines={false}
				centered
				width={430}
				closable={false}
			>
				<h3 className={styles.confirm__title}>Отсканированы не все места из списка</h3>
				<p className={styles.confirm__text}>Досканируйте или примите с расхождением</p>
			</Modal> */}
		</>
	);
};

export default observer(ItemsProductModal);
