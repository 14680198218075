import Button from '@components/buttons/button';
import Modal from '@components/modal';
import TextArea from '@components/textarea';
import { useStore } from '@hooks/useStore';
import Icon, { images } from '@images/index';
import StoresVisitsService, { IPass } from '@services/stores-visits';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { QRCodeSVG } from 'qrcode.react';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

const accesses = ['Доступ запрещен', 'Полный доступ', 'Частичный доступ', 'Технический доступ'];

const PassModal: FC = observer(() => {
	const { notice, visits, setLoading } = useStore();
	const [pass, setPass] = useState<IPass | null>(null);
	const [isConfirm, setIsConfirm] = useState<'success' | 'denied' | null>(null);
	const [comment, setComment] = useState('');

	const reset = (): void => {
		setPass(null);
		visits.setPassId(null);
		setComment('');
		setIsConfirm(null);
	};

	const handleCloseClick = (): void => {
		setIsConfirm('denied');
	};

	const handleConfirmClick = (): void => {
		setIsConfirm('success');
	};

	const handleSubmitClick = (): void => {
		if (!pass?.id) return;

		StoresVisitsService.sendPass(pass.id, { status: isConfirm === 'success' ? 2 : 3, comment })
			.then(reset)
			.catch((err) => notice.setNotice({ message: err?.error || err?.message || '' }))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (visits.passId) {
			setLoading(true);

			StoresVisitsService.createPass(visits.passId)
				.then(({ data }) => setPass(data))
				.catch((err) => {
					notice.setNotice({ message: err?.error || err?.message || '' });
					visits.setPassId(null);
				})
				.finally(() => setLoading(false));
		}
	}, [visits.passId]);

	if (!pass?.operator) return null;

	const {
		id,
		media,
		surname,
		name,
		patronymic,
		post_name: post,
		access_level: level,
		phone_number: phone,
	} = pass.operator;

	return (
		<Modal
			centered
			title={!isConfirm ? <images.Logo /> : null}
			open={!!pass?.operator}
			width={isConfirm ? 400 : 480}
			destroyOnClose
			onCancel={reset}
			className={cn(styles.modal, { [styles.modal__confirm]: isConfirm })}
			footer={
				!isConfirm ? (
					<div className={styles.footer}>
						<Button onClick={handleCloseClick}>Отказать</Button>
						<Button type="primary" onClick={handleConfirmClick}>
							Подтвердить
						</Button>
					</div>
				) : null
			}
			hasLines={!isConfirm}
			closable={!isConfirm}
			maskClosable={!isConfirm}
		>
			{isConfirm ? (
				<>
					<h3 className={styles.title__confirm}>
						<Icon imageName={isConfirm === 'success' ? 'CheckCircleFilled' : 'CloseCircleFilled'} fontSize={16} />
						<span>{isConfirm === 'success' ? 'Посещение зафиксировано' : 'В посещении отказано'}</span>
					</h3>
					<TextArea
						className={styles.comment}
						placeholder="При необходимости оставьте Ваш комментарий"
						value={comment}
						onChange={(evt) => setComment(evt.target.value)}
					/>
					<Button className={styles.button__confirm} type="primary" onClick={handleSubmitClick}>
						Ok
					</Button>
				</>
			) : (
				<>
					{media && <img width={164} src={media} alt={`${surname || ''} ${name || ''} ${patronymic || ''}`} />}
					<div className={styles.text}>
						<h2 className={styles.title}>
							<span className={styles.title__inner}>{surname}</span>
							<span>
								{`${name} `}
								{patronymic || ''}
							</span>
						</h2>
						{post && <span className={styles.post}>{post}</span>}
						<span className={styles.tag}>{accesses[level]}</span>
						{phone && <span className={styles.phone}>{phone}</span>}
					</div>
					<QRCodeSVG className={styles.qr} value={`{"type":"ep","owner":"employee","data":{"id":${id}}}`} size={256} />
				</>
			)}
		</Modal>
	);
});

export default PassModal;
