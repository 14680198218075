import { api } from '@http/index';
import { AxiosResponse } from 'axios';

export interface IPass {
	id: number;
	store_id: number;
	operator: {
		id: number;
		surname: string;
		name: string;
		patronymic?: string;
		phone_number?: number;
		post_name?: number;
		access_level: 0 | 1 | 2 | 3;
		media: string;
	};
	status: 1 | 2 | 3;
	comment?: string;
}

const createPass = (id: number): Promise<AxiosResponse<IPass>> => api.post(`stores-visits/${id}/create`);
const sendPass = (id: number, data: { status: 2 | 3; comment: string }): Promise<AxiosResponse<IPass>> =>
	api.put(`stores-visits/${id}`, data);

const StoresVisitsService = { createPass, sendPass };

export default StoresVisitsService;
