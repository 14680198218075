export const documentTypes = {
	label: 'Адресный ярлык',
	'sales-receipt': 'Товарный чек',
	'return-application': 'Заявление на возврат',
	'order-for-collect': 'Список товаров',
	'postal-for-collect': 'Список посылок в ячеках',
	// 'software-receipt': 'Квитанция о выдаче ПО',
	// 'acceptance-for-individuals': 'Квитанция при приемке для физ.лиц',
	// 'receipt-upon-acceptance-for-legal-entities': 'Квитанция при приемке для юр.лиц',
	thermal: 'Термоярлык',
	// 'receipt-issuence-np': 'Квитанция выдачи НП',
	'np-acceptance-receipt': 'Квитанции приемки НП',
	'get-acceptance-for-legal-entities': 'Квитанция пр групповой приемка для юр.лиц',
	'get-acceptance-for-individuals': 'Квитанция при приемке для физ.лиц',
	'get-group-issuing-cash-on-delivery': 'Квитанция выдачи НП',
	'get-issuance-for-individuals': 'Квитанция о выдаче ПО',
	'cash-register-report-pdf': 'Сменный итог по заказам',
	'cash-register-report-pdf-employees-general': 'Сменный итог по почтовым услугам - общий',
	'cash-register-report-pdf-employees-full': 'Сменный итог по почтовым услугам - детальный',
	'claim-act': 'Акт-претензия по почтовому отправлению',
	'autopsy-act': 'Акт на вскрытие',
	'defective-condition-act': 'Акт на внешний осмотр',
	qr: 'QR',
	'routing-lists': 'Маршрутный лист',
	'routing-surplus': 'Документ излишков',
	'refund-receipt': 'Квитанцию о выдачи возвратной ПО',
	'new-cell': 'Дополнительная ячейка',
	'voice-message': 'Повтор голосового помощника',
};

export type DocumentKeys = keyof typeof documentTypes;
