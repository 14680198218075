import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import ModalScannedCounter from '@pages/main/components/modal-scanned-counter';
import { Postal } from '@store/visits/postal';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalScannedCollectParcel: FC = () => {
	const visit = useCurrentVisit();
	const { print } = useStore();

	const getFullName = (): string => {
		const postal = visit?.postals[0];
		return `${postal?.receiver.surname} ${postal?.receiver.name} ${postal?.receiver.patronymic_name || ''}`;
	};

	const columns: ColumnsType<Postal> = [
		{
			dataIndex: 'postal',
			width: 350,
			title: 'Посылка',
			render: (_, { number }) => <span>{number}</span>,
		},
		{
			dataIndex: 'dimensions',
			width: 200,
			title: 'Габариты',
			render: (_, { width, height, length }) => {
				return <span>{width === 0 && height === 0 && length === 0 ? '' : `${width}*${height}*${length} см`}</span>;
			},
		},
		{
			dataIndex: 'cell',
			width: 200,
			title: 'Ячейка',
			render: (_, { number, store_place_item_full_name }) => <span>{store_place_item_full_name || ''}</span>,
		},
	];

	return (
		<ModalScannedCounter
			onClose={() => visit?.setIsShowModalCollectParcels(false)}
			isShow={!!visit?.isShowModalParcels}
			title={
				<span>
					<Icon imageName="Mail" /> Все посылки клиента
				</span>
			}
			footer={
				<div className={styles.footer}>
					<Button
						onClick={() =>
							print.setPrintPreview({ type: 'postal-for-collect', ids: visit?.postals.map((el) => el.id) })
						}
					>
						Печать списка посылок
					</Button>
					<Button onClick={() => visit?.setIsShowModalCollectParcels(false)} type="primary">
						Завершить сборку
					</Button>
				</div>
			}
			description={getFullName()}
			scannedCounter={visit?.postalCollectScannedItems?.length || 0}
			totalCounter={visit?.postalsForCollectParcels?.length || 0}
		>
			<Table
				pagination={false}
				className={styles.table}
				dataSource={visit?.postalsForCollectParcels as Postal[]}
				columns={columns}
				rowKey={(record) => record.id}
				onRow={({ number }) => ({
					className: cn(styles.row, {
						[styles.row__check]: visit?.isShowModalParcels && visit?.entryPostalCollectItems(number),
						// [styles.row__error]: isNewError,
					}),
				})}
			/>
		</ModalScannedCounter>
	);
};

export default observer(ModalScannedCollectParcel);
