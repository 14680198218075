/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, union, literal, number } = types;

const Header = model('acceptance-header', {
	count_routing_lists: number,
	has_postal: union(literal(0), literal(1)),
	has_emall: union(literal(0), literal(1)),
	has_money: union(literal(0), literal(1)),
	emall_bags: number,
	emall_products: number,
	postal_spaces: number,
	postal_trouble_items: number,
});

export default Header;
export interface IHeader extends SnapshotIn<typeof Header> {}
