import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const StaticCargoSpaces: FC = () => {
	const {
		shipment: {
			post: { consolidation },
		},
	} = useStore();

	const spaces = consolidation.spaces.static_spaces || [];

	return (
		<section className={styles.spaces}>
			<header className={styles.header}>
				<h2 className={styles.title}>Статичные места</h2>
			</header>
			{spaces.length > 0 ? (
				<ul className={styles.list}>
					{spaces.map(({ id, name, routing_items_count: count }) => (
						<li
							key={id}
							className={cn(styles.item)}
							onClick={() => consolidation.loadSpace({ id, isStatic: true })}
							role="presentation"
						>
							<Icon className={styles.icon} imageName="TempCargoSpace" />
							<span className={styles.name}>{name}</span>
							<span className={styles.count}>{count} шт.</span>
						</li>
					))}
				</ul>
			) : null}
		</section>
	);
};

export default observer(StaticCargoSpaces);
