import Button from '@components/buttons/button';
import { useCurrentVisit } from '@hooks/useCurrentVisit';
import Icon from '@images/index';
import ModalScannedCounter from '@pages/main/components/modal-scanned-counter';
import { IOrderCollectItem } from '@store/visits/order-collect';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import styles from './index.module.scss';

const ModalOrderCollect: FC = () => {
	const visit = useCurrentVisit();

	const columns: ColumnsType<IOrderCollectItem> = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			render: (_, { name }) => <div>{name}</div>,
			width: 453,
		},
		{
			dataIndex: 'cell',
			title: 'Ячейка',
			render: (_, { store_place_full_name }) => <div>{store_place_full_name}</div>,
			width: 117,
		},
		{
			dataIndex: 'count',
			title: 'Кол-во в заказе',
			render: (_, { quantity }) => <div>{quantity}</div>,
			width: 133,
		},
		{
			dataIndex: 'price',
			title: 'Цена',
			render: (_, { price }) => <div>{price}</div>,
			width: 120,
		},
		{
			dataIndex: 'collected_quantity',
			title: 'Кол-во факт',
			render: (_, { collected_count }) => <div>{collected_count}</div>,
			width: 120,
		},
		{
			dataIndex: 'final_price',
			title: 'Сумма',
			render: (_, { final_price }) => <div>{final_price}</div>,
			width: 88,
		},
	];

	return (
		<ModalScannedCounter
			classModal={styles.modal}
			onClose={visit?.closeModalOrderCollect}
			totalCounter={visit?.ordersCollect?.count || 0}
			scannedCounter={visit?.ordersCollect?.collected_count || 0}
			description={visit?.ordersCollect?.receiver_fio || ''}
			title={
				<div className={styles.header}>
					<Icon imageName="ShoppingBagBlack" />
					<div>
						{visit?.ordersCollect?.order_ids.length === 1
							? `${visit?.ordersCollect?.order_ids[0]}`
							: 'Все заказы клиента'}
					</div>
				</div>
			}
			isShow={!!visit?.ordersCollect}
			footer={
				<div className={styles.footer}>
					<Button>Печать списка товаров</Button>
					<Button type="primary">Завершить сборку</Button>
				</div>
			}
		>
			<Table
				pagination={false}
				className={styles.table}
				columns={columns}
				dataSource={visit?.ordersCollect?.items || []}
				rowClassName={({ collected_count, quantity }: IOrderCollectItem) => {
					if (collected_count !== quantity && collected_count !== 0) return styles.yellow;
					if (quantity === collected_count) return styles.green;
					return '';
				}}
			/>
			<div className={styles.footer}>
				<div>Сумма заказа</div>
				<div className={styles.total_price}>{visit?.ordersCollect?.total_price}</div>
			</div>
		</ModalScannedCounter>
	);
};

export default observer(ModalOrderCollect);
