/* eslint-disable @typescript-eslint/no-empty-interface */
import { ShipmentService } from '@services/shipment';
import { ShipmentPostalService } from '@services/shipment-postal';
import { cast, destroy, flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '../..';
import Postal, { IPostal } from './postal';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Goods = model('goods', {
	id: number,
	name: string,
	market_id: number,
	count: optional(number, 1),
}).actions((self) => ({
	setCount(value: number | null) {
		value && (self.count = value);
	},
}));

const PostalSpace = model('modal-postal-space', {
	count: number,
	scanned_count: number,
	items: array(Postal),
	id: number,
	type: maybeNull(number),
	type_name: maybeNull(string),
	name: maybeNull(string),
	isTrouble: false,
})
	.actions((self) => ({
		setIsTrouble(value: boolean) {
			self.isTrouble = value;
		},
	}))
	.views((self) => ({
		get surplusCount() {
			return self.items.filter(({ isSurplus }) => isSurplus).length;
		},
		get hasScanned() {
			return !!self.items.filter(({ is_scanned }) => is_scanned).length;
		},
		get sortItems() {
			return self.items.slice().sort((a, b) => {
				const isRedA = a.wrong_status_type && self.isTrouble;
				const isRedB = b.wrong_status_type && self.isTrouble;

				if (isRedA === isRedB) {
					if (a.isSurplus === b.isSurplus) {
						return a.is_scanned ? 1 : -1;
					}
					return a.isSurplus ? -1 : 1;
				}
				return isRedA ? -1 : 1;
			});
		},
	}));

const ModalGoodsTable = model('modal-goods-table', {
	barcode: maybeNull(number),
	goods: maybeNull(array(Goods)),
	sign: '',
	space: maybeNull(PostalSpace),
	isStatic: false,
	showConfirm: false,
}).actions((self) => {
	return {
		scanBarcodeTrouble: flow(function* (barcode: string) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет посылок' });
				throw 'Нет посылок';
			}

			root.setLoading(true);

			try {
				const item = self.space.items.find(({ number }) => number === barcode.toUpperCase());

				if (!item) {
					root.setLoading(false);
					root.notice.setNotice({ message: 'Посылка не найдена' });
					return;
				}

				if (item.wrong_status_type === 1) {
					yield ShipmentPostalService.postPostalTrouble(item.id);
					item.update({ is_scanned: 1 });
				}

				if (item.wrong_status_type === 2) {
					yield ShipmentPostalService.putPostalUnscan(item.id);
					item.update({ is_scanned: 1 });
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
				throw err?.error || err?.message || 'Ошибка';
			} finally {
				root.setLoading(false);
			}
		}),
		scanBarcode: flow(function* (barcode: string) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет посылок' });
				throw 'Нет посылок';
			}

			if (self.space.isTrouble) {
				root.shipment.post.consolidation.modalGoodsTable?.scanBarcodeTrouble(barcode);
				return;
			}

			root.setLoading(true);

			try {
				const item = self.space.items.find(({ number }) => number === barcode.toUpperCase());

				const {
					data: { routing_item: postal, scanning_status: status, message },
				} = yield ShipmentPostalService.postPostalScan({
					number: barcode,
					[self.isStatic ? 'static_place_id' : 'routing_space_id']: self.space.id,
				});

				switch (status) {
					case 1:
						item && item.update(postal);
						break;
					case 2:
						self.space.items.unshift({ ...postal, isSurplus: true });
						break;
					case 3:
						item && item.update({ ...postal, status: 'Неверный статус' });
						break;

					default:
						root.notice.setNotice({ message });
						break;
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
				throw err?.error || err?.message || 'Ошибка';
			} finally {
				root.setLoading(false);
			}
		}),
		setBarcode(value: number | null) {
			self.barcode = value;
		},
		setGoods(value: IGoods[]) {
			self.goods = cast(value);
		},
		removeItem: flow(function* (id: number) {
			const root = getRoot<typeof RootStore>(self);

			if (!self.space || !self.space.id) {
				root.notice.setNotice({ message: 'Нет товаров' });
				return;
			}

			root.setLoading(true);

			try {
				const foundItem = self.space.items.find((item) => item.id === id);

				if (foundItem) {
					yield ShipmentPostalService.putPostalUnscan(foundItem.id);
					destroy(foundItem);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		setSign(value: string) {
			self.sign = value;
		},
		setTroubleSpace(items: IPostal[]) {
			if (!self.space) return;
			self.space.setIsTrouble(true);
			self.space = PostalSpace.create({
				...self.space,
				items: items.map((item) => ({ ...item, is_scanned: 0 })),
				type_name: 'Проблемные посылки',
			});
		},
		setShowConfirm(value: boolean) {
			self.showConfirm = value;
		},
		closeTrouble() {
			const root = getRoot<typeof RootStore>(self);
			self.space?.setIsTrouble(false);
			root.shipment.post.consolidation.loadSpace({ id: self.space?.id || null, isStatic: self.isStatic });
		},
		submitSpace: flow(function* () {
			const root = getRoot<typeof RootStore>(self);
			if (self.space?.isTrouble) {
				root.shipment.post.consolidation.modalGoodsTable?.closeTrouble();
				return;
			}

			if (!self.space?.id) {
				root.notice.setNotice({ message: 'Ошибка' });
				return;
			}

			root.setLoading(true);

			try {
				const { data } = self.isStatic
					? yield ShipmentPostalService.postStaticPlacesShip(self.space.id)
					: yield ShipmentPostalService.getPostalNonShippable(self.space.id);

				if (data.length) {
					root.shipment.post.consolidation.modalGoodsTable?.setTroubleSpace(data);
				} else {
					if (self.isStatic) {
						root.shipment.post.consolidation.modalGoodsTable?.close();
						return;
					}
					root.shipment.post.consolidation.modalGoodsTable?.setShowConfirm(true);
				}
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		close() {
			if (self.space?.isTrouble) return this.closeTrouble();

			const root = getRoot<typeof RootStore>(self);
			root.shipment.post.consolidation.loadSpace(null);
			root.shipment.post.consolidation.loadSpaces();
		},
	};
});

const Space = model('space', {
	id: number,
	type: number,
	type_name: string,
	is_scanned: union(literal(0), literal(1)),
	number: maybeNull(string),
	routing_items_count: number,
	status: number,
});

const StaticSpace = model('space', {
	id: number,
	name: string,
	routing_items_count: number,
});

const Spaces = model('spaces', {
	dynamic_spaces: maybeNull(array(Space)),
	static_spaces: maybeNull(array(StaticSpace)),
}).views((self) => ({
	get disabledAdd() {
		if (!self.dynamic_spaces) return false;
		return self.dynamic_spaces.filter(({ status }) => status !== 40).length >= 3;
	},
}));

const ConsolidationModel = model('consolidation-model', {
	spaces: Spaces,
	modalGoodsTable: maybeNull(ModalGoodsTable),
	modalMoving: false,
	modalQr: maybeNull(number),
}).actions((self) => {
	const root = getRoot<typeof RootStore>(self);

	return {
		loadSpaces: flow(function* () {
			root.setLoading(true);
			try {
				const res = yield ShipmentPostalService.getPostalSpaces();
				self.spaces = res.data || null;
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		removeSpace: flow(function* (id: number) {
			root.setLoading(true);
			try {
				yield ShipmentService.deleteSpace(id);
				root.shipment.post.consolidation.loadSpaces();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		loadSpace: flow(function* (
			data: {
				id: number | null;
				addItems?: IPostal[] | null;
				isStatic?: boolean;
			} | null
		) {
			if (!data || !data.id) {
				self.modalGoodsTable = null;
				return;
			}

			const { id, addItems, isStatic = false } = data;
			root.setLoading(true);

			try {
				const res = yield ShipmentPostalService.getPostalSpaceItems(id, isStatic);
				const space = res.data;

				if (addItems) {
					addItems.forEach((item) => {
						const foundIndex = space.items.findIndex((o: IPostal) => o.id === item.id);

						if (foundIndex !== -1) {
							space.items[foundIndex].isSurplus = true;
							space.items.sort((a: IPostal, b: IPostal) => {
								const foundId = space.items[foundIndex].id;
								return +(b.id === foundId || b.isSurplus || false) - +(a.id === foundId || a.isSurplus || false);
							});
						} else {
							space.items.unshift(item);
						}
					});
				}

				self.modalGoodsTable = ModalGoodsTable.create({ space, isStatic });
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		addSpace: flow(function* () {
			root.setLoading(true);
			try {
				yield ShipmentPostalService.postPostalSpaces();
				root.shipment.post.consolidation.loadSpaces();
			} catch (err: any) {
				root.notice.setNotice({ message: err?.error || err?.message || '' });
			} finally {
				root.setLoading(false);
			}
		}),
		showModalMoving(value: boolean) {
			self.modalMoving = value;
		},
		setModalQr(value: number | null) {
			self.modalQr = value;
		},
	};
});

export default ConsolidationModel;

export interface IGoods extends SnapshotIn<typeof Goods> {} ////
export interface IPostalSpace extends SnapshotIn<typeof PostalSpace> {}
export interface ISpaces extends SnapshotIn<typeof Spaces> {}
