import Button from '@components/buttons/button';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { LabelService } from '@services/label';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';

import styles from './index.module.scss';

const QrModal: FC = () => {
	const [qr, setQr] = useState<ArrayBuffer | null>(null);
	const {
		print,
		shipment: {
			post: { consolidation },
		},
	} = useStore();

	const id = consolidation.modalGoodsTable?.space?.id;
	const close = (): void => {
		consolidation.setModalQr(null);
		setQr(null);
	};

	useEffect(() => {
		consolidation.modalQr && LabelService.getStaticSpaceQr(consolidation.modalQr).then((value) => setQr(value.data));
	}, [consolidation.modalQr]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.body}
			title={
				<>
					Печать QR-кода
					<span className={styles.description}>для места хранения временного груза</span>
				</>
			}
			open={!!consolidation.modalQr}
			onCancel={close}
			centered
			width={480}
			destroyOnClose
			footer={
				<div className={styles.footer}>
					<Button onClick={close}>Закрыть</Button>
					<Button type="primary" onClick={() => print.printTask({ type: 'static-space-qr', id })}>
						<Icon imageName="Print" />
						Печать
					</Button>
				</div>
			}
		>
			<Document className={styles.document} file={qr}>
				<Page pageNumber={1} width={256} height={256} scale={1} loading="Загрузка страницы" />
			</Document>
		</Modal>
	);
};

export default observer(QrModal);
