/* eslint-disable @typescript-eslint/no-empty-interface */
import { types } from 'mobx-state-tree';
import { getCharByKeyCode } from 'utils/getCharByKeyCode';

const { model } = types;

const Scanner = model('scanner', {
	value: '',
}).actions((self) => {
	let timerId: any;

	return {
		clear() {
			self.value = '';
		},
		handleScanner({ key, keyCode, shiftKey, target }: KeyboardEvent, action: (number: string) => void) {
			//@ts-ignore
			if (target.tagName === 'INPUT') return;

			clearTimeout(timerId);
			timerId = setTimeout(this.clear, 50);

			self.value += getCharByKeyCode(keyCode, shiftKey).trim();

			if (key === 'Enter' && self.value) {
				action(self.value);
				this.clear();
			}
		},
	};
});

export default Scanner;
