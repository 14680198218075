import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import ContextMenu from '@components/context-menu';
import Modal from '@components/modal';
// import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import PostalInfoModal from '@pages/main/visits/components/visit/compoments/postal-info-modal';
import OrderService from '@services/order';
import { EmallTask } from '@store/tasks';
import { message, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
// import { plural } from 'utils/plural';
import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';

import styles from './index.module.scss';

const EmallTasksTable: FC = () => {
	const store = useStore();
	const [cancellingOrder, setCancelllingOrder] = useState<EmallTask | null>(null);
	const [selectedItem, setSelectedItem] = useState<EmallTask | null>(null);
	const [messageApi, contextHolder] = message.useMessage({
		top: 160,
		duration: 2,
		prefixCls: 'messages',
	});

	const extendOrder = (id: number): void => {
		store.setLoading(true);
		OrderService.extendOrder(id)
			.then(({ data }) => messageApi.open({ type: 'success', content: data.message, className: styles.message }))
			.catch((err) => store.notice.setNotice({ message: err?.error || err?.message || '' }))
			.finally(() => {
				store.setLoading(false);
				store.loadTask();
			});
	};

	const columns: ColumnsType<EmallTask> = [
		{
			dataIndex: 'info',
			render: (_, { market_id, expired_days }) => (
				<div style={{ display: 'flex', gap: 8 }}>
					<div style={{ paddingTop: 5 }}>
						<Icon imageName="ShoppingBag" />
					</div>
					<div>
						<div style={{ fontWeight: 500 }}>{market_id}</div>
						{/* <div style={{ color: expired_days < 8 ? colors.black : colors.red }}> */}
						<div>
							Истек срок хранения
							{/* Истек срок {expired_days > 0 && `${expired_days} ${plural(expired_days, 'день', 'дня', 'дней')}`} */}
						</div>
					</div>
				</div>
			),
			width: 195,
		},
		{
			dataIndex: 'name',
			render: (_, { receiver_fio }) => <div>{receiver_fio}</div>,
			width: 328,
		},
		{
			dataIndex: 'phone',
			render: (_, { receiver_phone_number }) => <div style={{ width: 136 }}>{receiver_phone_number}</div>,
			width: 162,
		},
		{
			dataIndex: 'positions',
			render: (_, { bags }) => (
				<div>
					{bags?.map((value) => (
						<Tag key={value.id}>{value.name}</Tag>
					))}
				</div>
			),
		},
		{
			render: (_, task) => (
				<div style={{ display: 'flex', justifyContent: 'flex-end', gap: 12 }}>
					{/* <Button type="primary" onClick={unpropagatedClickHandler(() => extendOrder(task.id))}>
						Продлить на {task.can_extended_for} {plural(task.can_extended_for, 'день', 'дня', 'дней')}
					</Button> */}
					<Button onClick={unpropagatedClickHandler(() => setCancelllingOrder(task))}>Отменить</Button>
				</div>
			),
			width: 302,
		},
		{
			render: (_, { market_id, id }) => (
				<div className={styles.actions}>
					<ContextMenu
						items={[
							{
								text: 'Товарный чек',
								onClick: unpropagatedClickHandler(() =>
									store.print.setPrintPreview({ type: 'sales-receipt', number: String(market_id), id })
								),
							},
							{
								text: 'Список товаров',
								onClick: unpropagatedClickHandler(() =>
									store.print.setPrintPreview({ type: 'order-for-collect', number: String(market_id), id })
								),
							},
						]}
					>
						<IconButton imageName="Sheet" style={{ margin: '0 4px' }} />
					</ContextMenu>
				</div>
			),
			width: 44,
		},
	];
	return (
		<>
			{contextHolder}
			<Table
				showHeader={false}
				pagination={false}
				columns={columns}
				dataSource={store.tasks?.filteredOrders}
				onRow={(obj) => ({
					onClick: () => {
						setSelectedItem(obj);
					},
					className: styles.row,
				})}
				className={styles.table}
				locale={{ emptyText: 'Нет данных' }}
			/>
			<Modal
				className={styles.confirm}
				title={`Вы уверены, что хотите отменить заказ №${cancellingOrder?.market_id} ?`}
				okText={'Отменить заказ'}
				cancelText="Отмена"
				open={!!cancellingOrder}
				onCancel={() => setCancelllingOrder(null)}
				onOk={() => {
					store.setLoading(true);
					OrderService.cancelOrder({ orderId: cancellingOrder?.id as number }, { cancel_reason_id: 27 })
						.then(() => setCancelllingOrder(null))
						.catch((err) => store.notice.setNotice({ message: err?.error || err?.message || '' }))
						.finally(() => {
							store.setLoading(false);
							store.loadTask();
						});
				}}
				hasLines={false}
				centered
				width={416}
			/>
			<PostalInfoModal id={selectedItem?.id || null} type="order" onClose={() => setSelectedItem(null)} />
		</>
	);
};

export default observer(EmallTasksTable);
