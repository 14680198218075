import IconButton from '@components/buttons/icon_btn';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon, { ImageName } from '@images/index';
import { Sheet } from '@store/shipment/route-sheet';
import { Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';

import styles from './index.module.scss';

const checkImage = (status: number): ImageName => {
	let imageName: ImageName;

	switch (status) {
		case 10:
			imageName = 'Sheet';
			break;
		case 40:
			imageName = 'DeliveryParcel';
			break;
		default:
			imageName = 'CheckCircleFilled';
			break;
	}

	return imageName;
};

const RouteSheetTable: FC = () => {
	const { shipment, print } = useStore();

	const columns: ColumnsType<Sheet> = [
		{
			dataIndex: 'info',
			render: (_, { status, status_name: title }) => (
				<div className={styles.info}>
					<Tooltip title={title} color="rgba(25, 25, 25, .85)">
						<Icon imageName={checkImage(status)} color={status === 10 ? colors.grey2 : undefined} />
					</Tooltip>
					<span>Маршрутный лист </span>
				</div>
			),
			width: 200,
		},
		{
			dataIndex: 'type',
			render: (_, { has_emall, has_postal }) => (
				<span>
					{(has_postal && 'Почта') || ''}
					{(has_postal && has_emall && '/') || ''}
					{(has_emall && 'Emall') || ''}
				</span>
			),
			width: 120,
		},
		{
			dataIndex: 'id',
			render: (_, { id }) => <span style={{ width: 136 }}>{id}</span>,
			width: 120,
		},
		{
			dataIndex: 'count',
			render: (_, { routing_spaces_count }) => <span>Состав: {routing_spaces_count} шт.</span>,
			width: 135,
		},
		{
			dataIndex: 'name',
			render: (_, { driver_fio: fio }) => <span>{fio || ''}</span>,
		},
		{
			dataIndex: 'date',
			render: (_, { date }) => <span>{date || ''}</span>,
			width: 150,
		},
		{
			render: (_, { id }) => (
				<div className={styles.actions}>
					<IconButton
						imageName="Print"
						onClick={unpropagatedClickHandler(() => print.setPrintPreview({ type: 'routing-lists', id }))}
						style={{ margin: '0 4px' }}
					/>
				</div>
			),
			width: 44,
		},
	];
	return (
		<Table
			showHeader={false}
			pagination={false}
			columns={columns}
			dataSource={shipment.routeSheet.list ? [...shipment.routeSheet.list] : []}
			onRow={({ id }) => ({
				onClick: () => shipment.loadRoutingInfo(id),
				className: styles.row,
			})}
			className={styles.table}
			locale={{ emptyText: 'Нет данных' }}
		/>
	);
};

export default observer(RouteSheetTable);
