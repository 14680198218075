import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import Consolidation from './components/consolidation';
import Goods from './components/goods';

let timerId: any;

const Emall: FC = () => {
	const {
		shipment: {
			emall: { tab, goods, consolidation, handleScanner },
		},
	} = useStore();

	useScanner(handleScanner);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(tab === 'goods' ? goods.loadItems : consolidation.loadSpaces, 100);
		return () => clearTimeout(timerId);
	}, [tab]);

	return tab === 'goods' ? <Goods /> : <Consolidation />;
};

export default observer(Emall);
