import { api } from '@http/index';
import { Client } from '@store/identification';
import { AxiosResponse } from 'axios';

const create = (data: {
	phone_number: string;
	surname?: string;
	name?: string;
	patronymic_name?: string;
	flat_market_id?: number;
}): Promise<
	AxiosResponse<{
		city_id: number;
		city_name_full: string;
		client_market_id: number;
		flat_market_id: number;
		street_id: number;
		street_name: string;
		house_number_name: string;
		housing_name: string;
		flat_number: string;
		surname: string;
		name: string;
		patronymic_name: string;
	}>
> => api.post('/client/create', data);

const getClient = (phone: number): Promise<AxiosResponse<Client>> => api.get(`accounts?phone_number=${phone}`);

const getInfoIdentification = (data: {
	phone_number: string;
	surname: string;
}): Promise<AxiosResponse<{ status: 0 | 1 }>> =>
	api.get(`/client/identification-status?surname=${data.surname}&phone_number=${data.phone_number}`);

export const ClientService = { create, getClient, getInfoIdentification };
