import Button from '@components/buttons/button';
import IconButton from '@components/buttons/icon_btn';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

// import { unpropagatedClickHandler } from 'utils/unpropagatedClickHandler';
import StaticCargoSpaces from './components/static-spaces';
import styles from './index.module.scss';

const CargoSpaces: FC = () => {
	const {
		shipment: {
			post: { consolidation },
		},
	} = useStore();

	const handlePlusClick = (): void => {
		consolidation.addSpace();
	};

	const spaces = consolidation.spaces.dynamic_spaces || [];

	return (
		<div className={styles.wrap}>
			<section className={styles.spaces}>
				<header className={styles.header}>
					<h2 className={styles.title}>Транспортировочные грузоместа</h2>
					<IconButton
						className={cn({ [styles.disabled]: consolidation.spaces.disabledAdd })}
						imageName="Plus"
						stroke={colors.black}
						fontSize={16}
						onClick={handlePlusClick}
						disabled={consolidation.spaces.disabledAdd}
					/>
				</header>
				{spaces.length > 0 ? (
					<>
						<ul className={styles.list}>
							{spaces.map(({ id, routing_items_count: count, type_name: name, status, number }) => {
								const isLock = status === 40;

								return (
									<li
										key={id}
										className={cn(styles.item, {
											[styles.item__lock]: isLock,
											[styles.item__clickable]: !isLock,
										})}
										onClick={() => !isLock && consolidation.loadSpace({ id })}
										role="presentation"
									>
										<span className={styles.icon}>
											<Icon imageName={isLock ? 'Lock' : 'Unlock'} color={isLock ? colors.grey2 : colors.black} />
										</span>
										<span className={styles.name}>{name}</span>
										<span>{number || ''}</span>
										<span>{count ? `${count} шт.` : ''}</span>
										<span style={{ width: 30 }}>
											{!isLock && count === 0 && (
												<IconButton
													className={styles.action}
													imageName="Close"
													color={colors.black}
													onClick={(evt) => {
														evt.stopPropagation();
														// consolidation.removeSpace(id);
													}}
													disabled={!id}
												/>
											)}
											{/* {isLock && type === 3 && (
												<IconButton
													className={styles.action}
													imageName="Print"
													onClick={unpropagatedClickHandler(() =>
														print.setPrintPreview({ type: 'routing-surplus', id })
													)}
													style={{ margin: '0 4px' }}
												/>
											)} */}
										</span>
									</li>
								);
							})}
						</ul>
						<Button
							className={styles.button}
							size="large"
							icon={<Icon imageName="Moving" />}
							onClick={() => consolidation.showModalMoving(true)}
						>
							Замена пломбы
						</Button>
					</>
				) : null}
			</section>
			<StaticCargoSpaces />
		</div>
	);
};

export default observer(CargoSpaces);
