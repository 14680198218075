import ContextMenu from '@components/context-menu';
import colors from '@config/colors';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';

const PrintMenu: FC = () => {
	const { print } = useStore();

	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [isHover, setIsHover] = useState(false);

	const printQr = (type: 'new-cell' | 'voice-message'): void => {
		const data =
			type === 'voice-message'
				? {
						manualQr: 'RepeatVoiceMessage',
						description: 'Распечатайте QR-код для активации последнего звукового оповещения',
				  }
				: {
						manualQr: 'NewCell',
						description: 'Распечатайте QR-код для открытия/привязки дополнительной ячейки для одного заказа',
				  };

		print.setPrintPreview({ type, ...data });
		setIsOpenMenu(false);
	};

	return (
		<ContextMenu
			open={isOpenMenu}
			trigger="click"
			onOpenChange={(visible) => setIsOpenMenu(visible)}
			items={[
				{ text: 'Дополнительная ячейка', onClick: () => printQr('new-cell') },
				{ text: 'Голосовой помощник', onClick: () => printQr('voice-message') },
			]}
		>
			<Button
				icon={<Icon imageName="Plus" stroke={isHover ? colors.blue2 : colors.black} fontSize={16} />}
				onMouseOver={() => setIsHover(true)}
				onMouseOut={() => setIsHover(false)}
			>
				Распечатать QR-код
			</Button>
		</ContextMenu>
	);
};

export default observer(PrintMenu);
