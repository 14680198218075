import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import ItemsModal from './components/items-modal';
import ItemsProductModal from './components/items-product-modal';
import EmallTable from './components/table';

let timerId: any;

const Emall: FC = () => {
	const {
		operator,
		acceptance: { emall },
	} = useStore();

	useScanner(emall.handleScanner);

	useEffect(() => {
		if (operator && !emall.tab) {
			emall.setTab(operator.isEmallPackingMode ? 'batch' : 'product');
		}
	}, [operator]);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(emall.loadOrders, 100);
		return () => clearTimeout(timerId);
	}, []);

	return (
		<>
			<EmallTable />
			<ItemsModal />
			<ItemsProductModal />
		</>
	);
};

export default observer(Emall);
