import InputNumber from '@components/inputNumber';
import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.scss';

export type DriverType = {
	id: number | null;
	isMercenary: 0 | 1;
};

const DriverModal: FC = () => {
	const {
		operator,
		shipment: {
			modalInfo,
			routeSheet: {
				driverModal,
				setDriverModal,
				updateDriver,
				driverError,
				setDriverError,
				driverLabel,
				setDriverLabel,
			},
			confirmModalInfo,
		},
	} = useStore();

	const [driver, setDriver] = useState<DriverType | null>(null);

	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.driver', operator.id);
	};

	const closeDriverModal = (): void => {
		leave();
		setDriver(null);
		setDriverModal('');
		setDriverError('');
	};

	useEffect(() => {
		if (driverModal) {
			window.Echo &&
				operator?.id &&
				listenChannel(
					({ driver_id: id, owner }) => setDriver({ id: +id, isMercenary: owner === 'mercenary_courier' ? 1 : 0 }),
					'.routing.driver',
					operator.id
				);
		} else {
			closeDriverModal();
		}

		return leave;
	}, [driverModal]);

	return (
		<Modal
			classNameModal={styles.modal}
			className={styles.modal__body}
			title={null}
			okText={driverModal === 'del' ? 'Удалить' : 'Продолжить'}
			cancelText="Отмена"
			open={!!driverModal}
			onCancel={closeDriverModal}
			onOk={() => {
				const id = modalInfo?.id;
				if (!id) return;

				if (driverModal === 'confirm') {
					driver?.id && confirmModalInfo(id, driver.id);
				} else {
					updateDriver(id, driver);
				}
			}}
			okButtonProps={{ disabled: driverModal !== 'del' && !driver?.id }}
			hasLines={false}
			centered
			width={416}
			closable={false}
			destroyOnClose
		>
			<h3 className={styles.modal__title}>
				{driverModal === 'del' ? 'Вы уверены, что хотите удалить водителя?' : 'Отсканируйте QR-код водителя'}
			</h3>
			{driverModal === 'del' ? (
				<p className={styles.modal__description}>Отсканированные места не сохранятся</p>
			) : (
				<InputNumber
					className={styles.input}
					value={driver?.id}
					setValue={(value) => {
						setDriverError('');
						setDriver({ id: value, isMercenary: 0 });
					}}
				/>
			)}
			{driverModal === 'confirm' && (
				<Checkbox
					className={styles.checkbox}
					checked={driverLabel}
					onChange={(evt) => setDriverLabel(evt.target.checked)}
				>
					Распечатать документ
				</Checkbox>
			)}
			{driverError ? <p className={styles.error}>{driverError}</p> : null}
		</Modal>
	);
};

export default observer(DriverModal);
