import { cast, getRoot, getSnapshot, types } from 'mobx-state-tree';

import { RootStore } from '..';
import Visit from './visit';

const allNums = ['1', '2', '3', '4'];

const { model, map, number, maybeNull } = types;

const Visits = model('Visits', {
	visitMap: map(Visit),
	titleConfirmModal: '',
	closingVisitNum: '',
	passId: maybeNull(number),
})
	.actions((self) => ({
		addVisit() {
			const freeNums = allNums.filter((x) => !Array.from(self.visitMap.keys()).includes(x));
			self.visitMap.set(String(freeNums[0]), {});
			return freeNums[0];
		},
		closeVisit(visitNum: string) {
			if (self.visitMap.get(visitNum)?.postals.find(({ market_status: status }) => status === 10 || status === 15)) {
				self.closingVisitNum = visitNum;
				self.titleConfirmModal = 'Есть обработанные, но неоплаченные отправления. Закрыть визит?';
			} else {
				this.deleteVisit(visitNum);
			}
		},
		deleteVisit(visitNum: string) {
			const shiftedVisits = Array.from(self.visitMap.keys());
			shiftedVisits.forEach((item) => {
				const removing = self.visitMap.get(item);
				self.visitMap.delete(item);
				if (item !== visitNum) {
					self.visitMap.set(item, removing ? getSnapshot(removing) : {});
				}
			});
		},
		setConfirmModal(title: string) {
			self.titleConfirmModal = title;
			self.closingVisitNum = '';
		},
		setPassId(value: number | null) {
			self.passId = value;
		},
		handleScanner(evt: KeyboardEvent, visitNum?: string) {
			const root = getRoot<typeof RootStore>(self);

			root.scanner.handleScanner(evt, (value) => {
				if (visitNum) {
					const visit = root.visits.visitMap.get(visitNum) || null;
					if (visit && visit?.isShowModalParcels) {
						if (Array.isArray(visit?.postalCollectScannedItems) && visit.postalCollectScannedItems.includes(value)) {
							return;
						}

						const currentArray = visit.postalCollectScannedItems ? getSnapshot(visit.postalCollectScannedItems) : [];
						const newArray = Array.isArray(currentArray) ? [...currentArray, value] : [value];

						visit.postalCollectScannedItems = cast(newArray);
					}
					return;
				}

				try {
					const data: { type: string; owner: string; data: { id: number } } = JSON.parse(value);
					data.data.id && this.setPassId(data.data.id);
				} catch {
					return;
				}
			});
		},
	}))
	.views((self) => ({
		get minVisitId() {
			return Math.min(...Array.from(self.visitMap.keys()).map((v) => Number(v)));
		},
	}));

export default Visits;
