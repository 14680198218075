import { FC } from 'react';

import CargoSpaces from './components/cargo-spaces';
import MovingModal from './components/moving-modal';
import QrModal from './components/qr-modal';
import TableGoodsModal from './components/table-goods-modal';

const Consolidation: FC = () => {
	return (
		<>
			<CargoSpaces />
			<MovingModal />
			<TableGoodsModal />
			<QrModal />
		</>
	);
};

export default Consolidation;
