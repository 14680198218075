/* eslint-disable @typescript-eslint/no-empty-interface */
import { AcceptanceService } from '@services/acceptance';
import { flow, getRoot, SnapshotIn, types } from 'mobx-state-tree';

import { RootStore } from '..';

const { model, maybeNull, union, literal, array, string, number, optional } = types;

const Sheet = model('sheet', {
	id: number,
	status: number,
	status_name: string,
	has_emall: union(literal(0), literal(1)),
	has_postal: union(literal(0), literal(1)),
	routing_spaces_count: number,
	date: maybeNull(string),
	driver_fio: maybeNull(string),
	is_surplus: maybeNull(union(literal(0), literal(1))),
});

const RouteSheet = model('route-sheet', {
	list: maybeNull(array(Sheet)),
	showOld: optional(union(literal(0), literal(1)), 0),
	isModalInfoView: false,
	isModalInfoCargoSpace: false,
})
	.actions((self) => {
		const root = getRoot<typeof RootStore>(self);

		return {
			loadList: flow(function* () {
				root.setLoading(true);
				try {
					const res = yield AcceptanceService.getLists(self.showOld);
					self.list = res.data || null;
				} catch (err: any) {
					root.notice.setNotice({ message: err?.error || err?.message || '' });
				} finally {
					root.setLoading(false);
				}
			}),
			setShowOld(value: boolean) {
				self.list = null;
				self.showOld = value ? 1 : 0;
				root.acceptance.loadHeaderInfo();
			},
			setIsModalInfoView(value: boolean) {
				self.isModalInfoView = value;
			},
			setIsModalInfoCargoSpace(value: boolean) {
				self.isModalInfoCargoSpace = value;
			},
			handleScanner(evt: KeyboardEvent) {
				const { loadInfoByDriver, modalInfo, modalInfoByDriver } = root.acceptance;

				root.scanner.handleScanner(evt, (value) => {
					if (modalInfo && !modalInfo.isView) {
						modalInfo.findSpace(value, { is_scanned: 1 }, { hasSurplus: true });
						return;
					}
					!modalInfoByDriver?.id && loadInfoByDriver(value);
				});
			},
		};
	})
	.views((self) => ({
		get hasEntered() {
			return !!self.list?.find(({ status }) => status === 80);
		},
	}));

export interface Sheet extends SnapshotIn<typeof Sheet> {}
export default RouteSheet;
