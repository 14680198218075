import NoData from '@components/no-data';
import { useScanner } from '@hooks/useScanner';
import { useStore } from '@hooks/useStore';
import InfoModal from '@pages/main/shipment/components/route-sheet/components/info-modal';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import Table from './components/table';

let timerId: any;

const RouteSheet: FC = () => {
	const { shipment } = useStore();
	useScanner(shipment.routeSheet.handleScanner);

	useEffect(() => {
		clearTimeout(timerId);
		timerId = setTimeout(shipment.routeSheet.loadList, 100);
		return () => clearTimeout(timerId);
	}, []);

	return (
		<>
			{shipment.routeSheet.list?.length ? <Table /> : <NoData text="Маршрутных листов нет" />}
			<InfoModal />
		</>
	);
};

export default observer(RouteSheet);
