import Modal from '@components/modal';
import { useStore } from '@hooks/useStore';
import { leaveEvent, listenChannel } from '@services/socket';
import { observer } from 'mobx-react-lite';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect } from 'react';

import ConfirmModal from './components/confirm-modal';
import Header from './components/header';
import Table from './components/table';
import styles from './index.module.scss';

const TableGoodsModal: FC = () => {
	const {
		operator,
		shipment: {
			emall: {
				consolidation: { modalGoodsTable: modal },
			},
		},
		setLoading,
	} = useStore();

	const submit = (): void => modal?.setShowConfirm(true);
	const leave = (): void => {
		window.Echo && operator?.id && leaveEvent('.routing.number', operator.id);
	};

	useEffect(() => {
		if (window.Echo && operator?.id && modal?.show && !modal?.showConfirm) {
			listenChannel(
				({ number }) => {
					modal?.scanBarcode(+number).catch((err) => console.log('consolidation scanBarcode err:', err));
				},
				'.routing.number',
				operator.id
			);
		}

		return leave;
	}, [!!modal?.show, modal?.showConfirm]);

	useEffect(() => {
		if (modal?.show) setLoading(false);
	}, [!!modal?.show]);

	return (
		<>
			<Modal
				title="Ящик"
				okText="Привязать пломбу"
				cancelText="Сохранить"
				open={modal?.show}
				width={880}
				onCancel={modal?.close}
				onOk={submit}
				className={styles.body}
				classNameModal={styles.modal}
				centered
				destroyOnClose
				okButtonProps={{ disabled: !modal?.space?.hasScanned }}
			>
				<Header />
				<OverlayScrollbarsComponent className={styles.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<Table />
				</OverlayScrollbarsComponent>
			</Modal>
			<ConfirmModal />
		</>
	);
};

export default observer(TableGoodsModal);
