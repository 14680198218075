/* eslint-disable @typescript-eslint/no-empty-interface */
import { SnapshotIn, types } from 'mobx-state-tree';

const { model, union, literal, number } = types;

const Header = model('shipment-header', {
	count_routing_lists: number,
	emall_consolidate_count: number,
	emall_count: number,
	postal_consolidate_count: number,
	postal_count: number,
	has_postal: union(literal(0), literal(1)),
	has_emall: union(literal(0), literal(1)),
	has_money: union(literal(0), literal(1)),
});

export default Header;
export interface IHeader extends SnapshotIn<typeof Header> {}
