import Button from '@components/buttons/button';
import { useStore } from '@hooks/useStore';
import Icon from '@images/index';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { formatPhoneNumber } from 'utils/formatPhone';

import ModalIndentify from '../modal-identify';
import ModalSuccess from '../modal-success';
import styles from './index.module.scss';

const ClientInfo: FC = () => {
	const { indentification } = useStore();

	return (
		<>
			{indentification.user?.id ? (
				<div className={styles.wrapper}>
					<div className={styles.wrapper__info}>
						<div className={styles.info}>
							<Icon className={styles.icon__acc} imageName="AccountIcon" />
							<div>
								<p className={styles.text}>{`${indentification.user.surname || ''} ${indentification.user.name || ''} ${
									indentification.user.patronymic_name || ''
								}`}</p>
								<p className={cn(styles.status, { [styles.status__success]: indentification.user.is_verified === 1 })}>
									{indentification.user.is_verified === 1 ? 'Идентифицирован' : 'Не идентифицирован'}
								</p>
							</div>
						</div>
						<div>
							<p className={styles.text}>{formatPhoneNumber(`${indentification.user.phone_number}`)}</p>
							<p>{indentification.user.address_name}</p>
						</div>
					</div>
					{indentification.user.is_verified === 1 && (
						<Button icon={<Icon imageName="MailGray" />} onClick={indentification.sendClientCode}>
							Отправить SMS
						</Button>
					)}
					{indentification.user.is_verified === 0 && indentification.user.vc_attempts_remaining !== 0 && (
						<Button onClick={() => indentification.setIsOpenModal(true)} type="primary">
							Идентифицировать
						</Button>
					)}
					{indentification.user.is_verified === 0 && indentification.user.vc_attempts_remaining === 0 && (
						<p className={styles.error}>Попытки отправки кода на сегодня исчерпаны</p>
					)}
				</div>
			) : null}
			<ModalIndentify />
			{indentification.isOpenModalSuccess && <ModalSuccess />}
		</>
	);
};
export default observer(ClientInfo);
